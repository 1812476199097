import fetch from 'node-fetch';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';

const shopifyClient = createStorefrontApiClient({
  storeDomain: `maobox.myshopify.com`,
  apiVersion: '2024-10',
  publicAccessToken: process.env.SHOP_TOKEN,
  customFetchApi: fetch,
});

export { shopifyClient };
