const transformCheckout = checkout => {
  console.log('checkout', checkout);
  if (!checkout) {
    return null;
  }

  const lineItems = checkout.lines.edges.map(({ node }) => {
    console.log('transform');
    console.log(node);
    return {
      id: node.id,
      title: node.merchandise.product.title,
      variant: {
        id: node.merchandise.id,
        title: node.merchandise.product.title,
        price: node.merchandise.price,
        image: {
          src: node.merchandise.image.url,
        },
        //quantity: node.quantity,
      },
      quantity: node.quantity,
    };
  });

  return {
    lineItems,
    totalPrice: checkout.cost.totalAmount,
    checkoutUrl: checkout.checkoutUrl,
  };
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CHECKOUT':
      return {
        ...state,
        //checkout: action.checkout || state.checkout,
        checkout2: transformCheckout(action.checkout2) || state.checkout2,
        isAdding: true,
        currentLineItem: action.currentLineItem,
      };
    case 'REMOVE_CHECKOUT':
      return {
        ...state,
        isRemoving: true,
        currentLineItem: action.currentLineItem,
      };
    case 'UPDATE_QUANTITY_CHECKOUT':
      return {
        ...state,
        isAdding: true,
        currentLineItem: action.currentLineItem,
      };
    case 'UPDATE_CHECKOUT_DONE':
      return {
        ...state,
        //checkout: action.checkout,
        checkout2: transformCheckout(action.checkout2),
        isAdding: false,
        isRemoving: false,
      };
    case 'TOGGLE_CART':
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      };

    default:
      return state;
  }
};
