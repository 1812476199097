import React, { createContext, useReducer, useEffect, useContext } from 'react';
//import Client from 'shopify-buy';
import { shopifyClient } from '../functions/shopify-cart';

import { cartReducer, transformCheckout } from '../reducers/CartReducer';
//import { parse } from 'dotenv';

const SHOPIFY_CHECKOUT_STORAGE_KEY = 'shopify_checkout_id';
const SHOPIFY_CHECKOUT_STORAGE_KEY_2 = 'shopify_checkout_id_2';

const quantities = {
  MAO1: 1,
  MAO3: 3,
  MAO6: 6,
  PMAO1: 1,
  PMAO3: 3,
  PMAO6: 6,
  JMAO1: 1,
  JMAO3: 3,
  JMAO6: 6,
};

// const client = Client.buildClient({
//   storefrontAccessToken: process.env.SHOP_TOKEN,
//   domain: `${process.env.SHOP_NAME}.myshopify.com`,
// });

const FETCH_CART = `
  query fetchCart($checkoutId: ID!) @inContext(country: SG) {
    cart(id: $checkoutId) {
      id
      lines(first: 5) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
          }
        }
      }
      buyerIdentity {
        email
      }
      attributes {
        key
        value
      }
      note
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
  }
`;

const CREATE_CHECKOUT_MUTATION = `
mutation createCart(
  $attributes: [AttributeInput!]
  $buyerIdentity: CartBuyerIdentityInput
  $discountCodes: [String!]
  $lines: [CartLineInput!]
  $metafields: [CartInputMetafieldInput!]
  $note: String
) @inContext(country: SG) {
  cartCreate(
    input: {
      attributes: $attributes
      buyerIdentity: $buyerIdentity
      discountCodes: $discountCodes
      lines: $lines
      metafields: $metafields
      note: $note
    }
  ) {
    cart {
      id
      lines(first: 5) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
          }
        }
      }
      buyerIdentity {
        email
        countryCode
      }
      attributes {
        key
        value
      }
      note
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
    userErrors {
      code
      field
      message
    }
  }
}
`;

const UPDATE_CART_MUTATION = `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) @inContext(country: SG) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      lines(first: 5) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
          }
        }
      }
      buyerIdentity {
        email
      }
      attributes {
        key
        value
      }
      note
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
    userErrors {
      field
      message
    }
  }
}`;

const REMOVE_CART_LINE_MUTATION = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) @inContext(country: SG) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      lines(first: 5) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
          }
        }
      }
      buyerIdentity {
        email
      }
      attributes {
        key
        value
      }
      note
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
    userErrors {
      field
      message
    }
  }
}`;

const UPDATE_CART_LINE_MUTATION = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext(country: SG) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      lines(first: 5) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
          }
        }
      }
      buyerIdentity {
        email
      }
      attributes {
        key
        value
      }
      note
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
    userErrors {
      field
      message
    }
  }
}`;

async function createCheckout2() {
  try {
    const response = await shopifyClient.request(CREATE_CHECKOUT_MUTATION, {
      variables: {
        lines: [],
        discountCodes: [],
        metafields: [],
        attributes: [],
      },
    });
    //const checkout = response;
    const checkout2 = response.data.cartCreate.cart;

    console.log('New checkout created:', checkout2);
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2, checkout2.id);
    return checkout2;
  } catch (error) {
    console.error('Error creating checkout:', error);
  }
}

function createNewCheckout(cart) {
  return cart.checkout.create();
}

function fetchCheckout(client, id) {
  //return client.checkout.fetch(id);
}

const initialCartState = {
  //client,
  isDrawerOpen: false,
  isAdding: false,
  currentLineItem: null,
  checkout: { lineItems: [] },
  checkout2: { lineItems: [] },
};

const CartContext = createContext({
  cart: initialCartState,
  setCart: () => null,
});

const CartContextProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialCartState);

  useEffect(() => {
    const initializeCheckout = async () => {
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null;

      const existingCheckoutId2 = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2)
        : null;

      if (existingCheckoutId2) {
        console.log('Existing checkout id 2:', existingCheckoutId2);
        try {
          const checkout2 = await shopifyClient.request(FETCH_CART, {
            variables: {
              checkoutId: existingCheckoutId2,
            },
          });

          console.log('Checkout 2 fetched from Shopify with id', checkout2.id);
          console.log('Checkout 2 contains', checkout2);
          if (checkout2) {
            console.log('Updating checkout 2 in browser', checkout2);
            dispatch({
              type: 'UPDATE_CHECKOUT_DONE',
              checkout2: checkout2.data.cart,
            });
          }
          return {
            //client,
            checkout2,
            isAdding: false,
          };
        } catch (error) {
          console.log(
            'Something went wrong and the checkout key had to be erased'
          );
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2, null);
        }
      }

      // if (existingCheckoutId) {
      //   try {
      //     const checkout = await fetchCheckout(client, existingCheckoutId);
      //     if (!checkout.completedAt) {
      //       console.log('Checkout fetched from Shopify with id', checkout.id);
      //       console.log('Checkout contains', checkout.lineItems);
      //       if (checkout) {
      //         console.log('Updating checkout in browser');
      //         dispatch({ type: 'UPDATE_CHECKOUT_DONE', checkout });
      //       }
      //       return {
      //         client,
      //         checkout,
      //         isAdding: false,
      //       };
      //     }
      //   } catch (error) {
      //     console.log(
      //       'Something went wrong and the checkout key had to be erased'
      //     );
      //     localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
      //   }
      // }
      //const newCheckout = await createNewCheckout(client);
      const newCheckout2 = await createCheckout2();

      //console.log('new checkout:', newCheckout);
      console.log('new checkout2:', newCheckout2);

      //localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, newCheckout.id);
      localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2, newCheckout2.id);

      console.log('The new checkout id stored is', newCheckout2.id);

      dispatch({ type: 'UPDATE_CHECKOUT_DONE', checkout2: newCheckout2 });

      return {
        //client,
        //newCheckout,
        newCheckout2,
        isAdding: false,
      };
    };

    initializeCheckout();
  }, []);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

function useAddItemToCart() {
  const { dispatch } = useContext(CartContext);

  async function addItemToCart(variantId, quantity) {
    if (variantId === '' || typeof quantity === 'undefined') {
      console.error('Both a size and quantity are required.');
      return;
    }

    const isBrowser = typeof window !== 'undefined';
    const checkoutId = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
      : null;
    const checkoutId2 = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2)
      : null;
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    dispatch({ type: 'UPDATE_CHECKOUT', currentLineItem: variantId });

    // const newCheckout = await client.checkout.addLineItems(
    //   checkoutId,
    //   lineItemsToAdd
    // );

    const newCheckout2 = await shopifyClient.request(UPDATE_CART_MUTATION, {
      variables: {
        cartId: checkoutId2,
        lines: [
          {
            merchandiseId: variantId,
            quantity: parseInt(quantity, 10),
          },
        ],
      },
    });

    //console.log(newCheckout2);

    //dispatch({ type: 'UPDATE_CHECKOUT_DONE', checkout: newCheckout });
    dispatch({
      type: 'UPDATE_CHECKOUT_DONE',
      checkout2: newCheckout2.data.cartLinesAdd.cart,
      // checkout2: {
      //   lineItems: newCheckout2.data.cartLinesAdd.cart.lines.edges.map(
      //     line => ({
      //       id: line.node.id,
      //       title: line.node.title,
      //       variant: {
      //         id: line.node.merchandise.id,
      //       },
      //     })
      //   ),
      // },
    });

    // console.log('====cart');
    // console.log(newCheckout2.data.cartLinesAdd.cart);
  }

  return addItemToCart;
}

function useUpdateItemInCart() {
  const { dispatch } = useContext(CartContext);

  async function updateItemInCart(id, quantity) {
    console.log('Updating item in cart:', id, quantity);
    if (id === '' || typeof quantity === 'undefined') {
      console.error('Both a size and quantity are required.');
      return;
    }

    const isBrowser = typeof window !== 'undefined';
    const checkoutId = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
      : null;

    const checkoutId2 = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2)
      : null;
    const lineItemsToUpdate = [{ id, quantity: parseInt(quantity, 10) }];

    dispatch({ type: 'UPDATE_QUANTITY_CHECKOUT', currentLineItem: id });

    // const newCheckout = await client.checkout.updateLineItems(
    //   checkoutId,
    //   lineItemsToUpdate
    // );

    //console.log('line items to update:', lineItemsToUpdate);

    const newCheckout2 = await shopifyClient.request(
      UPDATE_CART_LINE_MUTATION,
      {
        variables: {
          cartId: checkoutId2,
          lines: lineItemsToUpdate,
        },
      }
    );

    console.log(newCheckout2.data.cartLinesUpdate.cart);

    dispatch({
      type: 'UPDATE_CHECKOUT_DONE',
      checkout2: newCheckout2.data.cartLinesUpdate.cart,
    });
  }

  return updateItemInCart;
}

function useRemoveItemFromCart() {
  const { dispatch } = useContext(CartContext);

  async function removeItemFromCart(variantId) {
    if (!variantId) {
      console.error('Missing variant id');
      return;
    }

    const isBrowser = typeof window !== 'undefined';
    const checkoutId = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
      : null;

    const checkoutId2 = isBrowser
      ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY_2)
      : null;
    const lineItemIdsToRemove = [variantId];

    dispatch({ type: 'REMOVE_CHECKOUT', currentLineItem: variantId });

    // const newCheckout = await client.checkout.removeLineItems(
    //   checkoutId,
    //   lineItemIdsToRemove
    // );

    const newCheckout2 = await shopifyClient.request(
      REMOVE_CART_LINE_MUTATION,
      {
        variables: {
          cartId: checkoutId2,
          lineIds: lineItemIdsToRemove,
        },
      }
    );

    dispatch({
      type: 'UPDATE_CHECKOUT_DONE',
      checkout2: newCheckout2.data.cartLinesRemove.cart,
    });
  }

  return removeItemFromCart;
}

function useGoToCheckout() {
  const { cart } = useContext(CartContext);

  return () => {
    console.log('Going to Checkout!', cart.checkout2.checkoutUrl);
    window.open(cart.checkout2.checkoutUrl);
  };
}

function useToggleCart() {
  const { dispatch } = useContext(CartContext);
  const toggleCart = isDrawerOpen => {
    dispatch({ type: 'TOGGLE_CART', isDrawerOpen });
  };

  return toggleCart;
}

async function useShopifyStore() {
  const shopifyInfo = await shopifyClient.request(`query {
    shop {
      moneyFormat

    }
  }`);

  console.log(shopifyInfo);
  return shopifyInfo;
}

export {
  CartContext,
  CartContextProvider,
  useAddItemToCart,
  useUpdateItemInCart,
  useRemoveItemFromCart,
  useGoToCheckout,
  useToggleCart,
  useShopifyStore,
  quantities,
};
